@charset "UTF-8";
@import "config";
@import "icon";

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

// web fontを使う場合
@mixin icon_ {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;

  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sp
@mixin sp {
  @media (max-width: 425px) {
    @content;
  }
}

// SE
@mixin se {
  @media (max-width: 320px) {
    @content;
  }
}

// font-awesome
@mixin font-awesome($content) {
	font-family: "Font Awesome 5 Free";
	content : $content;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-weight: 700;
	@content;
}

// hover
@mixin hover-underline {
  text-decoration: underline;
  @include sp {
    text-decoration: inherit;
  }
}
@mixin hover-bgcolor {
  background-color: $primary_color;
  color: #fff;
  @include sp {
    background-color: inherit;
    color: inherit;
  }
}
@mixin hover-opacity {
  opacity: .8;
  @include sp {
    opacity: 1;
  }
}
@mixin hover-saturate {
  filter: saturate(150%);
  @include sp {
    filter: none;
  }
}
@mixin hover-text_link {
  text-decoration: underline;
  color: $primary_color;
  @include sp {
    text-decoration: inherit;
    color: inherit;
  }
}