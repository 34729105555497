@charset "UTF-8";

body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  position: relative;
  color: #333;
  font-size: 14px;
  padding-top: 90px;
  min-width: $content_width;
  top: 0 !important;
  @include sp {
    min-width: 0;
    padding-top: 65px;
  }
}
.sp {
  display: none;
  @include sp {
    display: inherit;
  }
}
.pc {
  display: block;
  @include sp {
    display: none;
  }
}

p {
  line-height: 1.5;
}

img {
  max-width: 100%;
}

a {
  color: #333;
  text-decoration: none;
  transition: .3s all;
}

a[href*="tel:"] {
  pointer-events: none;
  cursor: default;
  @include sp {
    pointer-events: auto;
  }
}

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.3s ease 0s;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 15px;
  box-sizing: border-box;
  line-height: 1;
  background: transparent;
  &:focus{
    outline: none;
    box-shadow: 0 0 0 1px $primary_color;
  }
  &:placeholder-shown {
    color: #b3b3b3;
  }
  &::-webkit-input-placeholder {
    color: #b3b3b3;
  }
  &:-moz-placeholder {
    color: #b3b3b3;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: #b3b3b3;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #b3b3b3;
  }
  &::-ms-expand {
    display: none;
  }
  &::-ms-value {
    background: none;
    color: #000;
  }
  &::-ms-clear {
    visibility:hidden;
  }
}
