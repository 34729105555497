@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  position: relative;
  color: #333;
  font-size: 14px;
  padding-top: 90px;
  min-width: 1140px;
  top: 0 !important;
}

@media (max-width: 425px) {
  body {
    min-width: 0;
    padding-top: 65px;
  }
}

.sp {
  display: none;
}

@media (max-width: 425px) {
  .sp {
    display: inherit;
  }
}

.pc {
  display: block;
}

@media (max-width: 425px) {
  .pc {
    display: none;
  }
}

p {
  line-height: 1.5;
}

img {
  max-width: 100%;
}

a {
  color: #333;
  text-decoration: none;
  transition: .3s all;
}

a[href*="tel:"] {
  pointer-events: none;
  cursor: default;
}

@media (max-width: 425px) {
  a[href*="tel:"] {
    pointer-events: auto;
  }
}

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.3s ease 0s;
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 15px;
  box-sizing: border-box;
  line-height: 1;
  background: transparent;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  box-shadow: 0 0 0 1px #e8382f;
}

input:placeholder-shown, select:placeholder-shown, textarea:placeholder-shown {
  color: #b3b3b3;
}

input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #b3b3b3;
}

input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder {
  color: #b3b3b3;
  opacity: 1;
}

input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
  color: #b3b3b3;
  opacity: 1;
}

input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #b3b3b3;
}

input::-ms-expand, select::-ms-expand, textarea::-ms-expand {
  display: none;
}

input::-ms-value, select::-ms-value, textarea::-ms-value {
  background: none;
  color: #000;
}

input::-ms-clear, select::-ms-clear, textarea::-ms-clear {
  visibility: hidden;
}
