@charset "UTF-8";

//カラー
$primary_color : #e8382f;
$gray: #f2f2f2;
$brown: #5f4331;
$yellow: #fcd550;

//幅
$content_width: 1140px;

//フォント
$serif: 'EB Garamond', serif;

//装飾
$shadow: 2px 2px 2px 0px rgba(0,0,0,0.1);